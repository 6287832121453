<template>
  <div class="container overflow-y-auto">
    <Navbar title="合同" />
    <div class="mt-12">
      <div
        class="mx-12 py-12 mb-12 box-border bg-white rounded-4"
        v-for="(item, index) in contractList"
        :key="index"
        @click="handlePreview(item)"
      >
        <div class="text-16">
          {{ item.contractName }}<span v-if="configShow && item.type === 1">《委托代开增值税专用发票协议》</span>
        </div>
        <span class="text-12 text-muted ml-8" v-if="item.type === 1">{{ item.taxSourceCompanyName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from '@/utils/compsitionHack';
import { useWaybillStore } from '@/store/waybill';
import { defineComponent } from '@vue/composition-api';
import { PageEnum } from '@/enums/pageEnum';
import { useUserStore } from '@/store/user';
import { useRouterQuery } from '@hook/useRouterQuery';

export default defineComponent({
  setup() {
    const router = useRouter();
    const waybillStore = useWaybillStore();
    const userStore = useUserStore();
    const contractList = waybillStore.contractList;
    const routerQuery = useRouterQuery();
    const configShow = routerQuery.configShow;

    const handlePreview = item => {
      router.push({
        path: PageEnum.AGREEMENT,
        query: {
          companyId: item.companyId || routerQuery.companyId,
          driverId: userStore?.driverId,
          taxSourceId: item.taxSourceId,
          type: item.type,
          transportBillNumber: routerQuery.transportBillNumber,
          weight: routerQuery.weight,
          volume: routerQuery.volume,
          number: routerQuery.number
        }
      });
    };

    return {
      configShow,
      contractList,
      handlePreview
    };
  }
});
</script>

<style lang="less" scoped>
.container {
  background: #f5f5f5;
  height: 100vh;
}
</style>
